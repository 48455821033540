<template>
    <div v-if="isLoading">
        <v-row justify="center">
            <tui-loader></tui-loader>
        </v-row>
    </div>
    <div v-else>
        <template v-if="comments && comments.length > 0">
            <v-row v-for="comment in comments" v-bind:key="comment.id" class="mb-4">
                <v-col class="flex-grow-0">
                    <v-avatar color="indigo" size="48" class="mr-4">
                        <span class="white--text headline">{{ comment.createdBy.name | toInitials }}</span>
                    </v-avatar>
                </v-col>

                <v-col>
                    <v-row>
                        <span class="title">{{ comment.createdBy.name }}</span>

                        <v-spacer></v-spacer>

                        <span class="ml-1 caption grey--text">{{ comment.createdAt | moment('from', 'now') }}</span>
                    </v-row>

                    <v-row>
                        <span class="grey--text text--darken-2 body-1">{{ comment.comment }}</span>
                    </v-row>
                </v-col>
            </v-row>
        </template>
        <template v-else>
            <v-row>
                <v-col>
                    <v-row class="px-4">
                        No hay comentarios para mostrar
                    </v-row>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
    import TuiLoader from '../../ui/TUILoader';
    export default {
        components: { TuiLoader },

        data() {
            return {
                isLoading: false
            };
        },

        props: {
            order: Object
        },

        computed: {
            comments() {
                return this.order.comments;
            }
        },

        methods: {

        }
    };
</script>

<style lang="scss" scoped>
    .v-list {
        background: none;
    }
</style>
